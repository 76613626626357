<template>
<div>
  <b-container>
    <b-row class="my-5">
      <b-col>
        <h1 style="font-size: 24px; font-weight: bold;">Welcome to the Climate Policy and Regulatory Dashboard</h1>
        <p>Curated by a global network of specialists, KPMG's Climate and Regulatory Dashboard (CPRD) enables you to make informed decisions, by simplifying the
          complex landscape of climate policy, incentives, and grants, offering a consolidated up to date view.
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12">
        <h2 class="mb-4" style="font-size: 20px; font-weight: bold;">Get Started</h2>
      </b-col>
      <b-col class="mb-5" lg="3" md="6">
        <router-link :to="{ name: 'ImpactPoliciesDashboard' }" class="link-card">
          <div class="shadow p-0 m-0 bg-white" style="height: 220px;">
            <div class="p-0 m-0" style="text-align: center; height: 130px;"><img src="/img/icon1.png" style="width: 200px" /></div>
            <div class="p-3 m-0" style="text-align: center;">View the world dashboard</div>
          </div>
        </router-link>
      </b-col>
      <b-col class="mb-5" lg="3" md="6">
        <router-link :to="{ name: 'ImpactPoliciesCountry', params: { country: countrySelected } }" class="link-card">
          <div class="shadow p-0 m-0 bg-white" style="height: 220px;">
            <div class="p-0 m-0" style="text-align: center; height: 130px;"><img src="/img/icon2.png" style="width: 200px" /></div>
            <div class="p-3 m-0" style="text-align: center;">Investigate a market's policies</div>
          </div>
        </router-link>
      </b-col>
      <b-col class="mb-5" lg="3" md="6">
        <router-link :to="{ name: 'ImpactPoliciesCountryComparison', params: { country1: countrySelected, country2: countrySelected2 } }" class="link-card">
          <div class="shadow p-0 m-0 bg-white" style="height: 220px;">
            <div class="p-0 m-0" style="text-align: center; height: 130px;"><img src="/img/icon3.png" style="width: 200px" /></div>
            <div class="p-3 m-0" style="text-align: center;">Compare policies across markets</div>
          </div>
        </router-link>
      </b-col>
      <b-col class="mb-5" lg="3" md="6">
        <router-link :to="{ name: 'ImpactPolicies' }" class="link-card">
          <div class="shadow p-0 m-0 bg-white" style="height: 220px;">
            <div class="p-0 m-0" style="text-align: center; height: 130px;"><img src="/img/icon4.png" style="width: 200px" /></div>
            <div class="p-3 m-0" style="text-align: center;">Explore policy areas</div>
          </div>
        </router-link>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mb-5" lg="8" md="12">
        <h2 style="font-size: 20px; font-weight: bold;">About Climate Policy and Regulatory Dashboard</h2>
        <p>An introduction from Mike Hayes and Loek Helderman</p>
        <b-container class="shadow p-3 bg-white">
          <b-row>
            <b-col class="text-center" lg="4" sm="12">
              <div><img src="/img/MikeHayes.png" style="width: 153px; height: 153px;" /></div>
              <p class="mb-0"><strong>Mike Hayes</strong></p>
              <p class="mb-0"><small>Climate Change and Decarbonization Leader, Global Head of Renewable Energy</small></p>
              <p><small>KPMG International</small></p>
              <div><img src="/img/LoekHelderman.png" style="width: 153px; height: 153px;" /></div>
              <p class="mb-0"><strong>Loek Helderman</strong></p>
              <p class="mb-0"><small>Global Tax & Legal Lead, KPMG ESG</small></p>
              <p><small>KPMG International</small></p>
            </b-col>
            <b-col lg="8" sm="12">
              <div>
                <p>Dear Beta Participants,</p>
                <p>Thank you for your support throughout the beta testing phase of KPMG’s Climate Policy & Regulatory Dashboard (CPRD).
                  Your invaluable insights and feedback have played a pivotal role in shaping the development of this exciting tool.
                  Hopefully you can see that we have incorporated your suggestions in the latest updates we have made to the CPRD’s content and features.
                  We will continue to refine the platform based on your feedback while considering a range of premium features for longer term development.</p>
                <p>KPMG continues to see a global lift in ambition for credible climate policy and regulation, which is increasingly translating into
                  cross border impacts and a contagion of cross border uptake. The CPRD is intended to help you navigate this complexity and anticipate
                  future impacts while plugging you into KPMG’s extensive global network of specialists that can provide the bespoke advice and analysis on particular topics.</p>
                <p>We look forward to discussing your continued access as we move the CPRD product into the market.
                  The value our clients place in this tool will determine our ability to increase the scope and depth of its coverage over time.</p>
              </div>
            </b-col>
          </b-row>
        </b-container>
        <h2 class="mt-5" style="font-size: 20px; font-weight: bold;">Contact us</h2>
        <p>Provide feedback, request features or support</p>
        <b-container class="shadow p-3 bg-white">
          <b-row>
            <b-col>
              <contact-form />
            </b-col>
          </b-row>
        </b-container>
      </b-col>
      <b-col lg="4" md="12">
        <h2 style="font-size: 20px; font-weight: bold;">News</h2>
        <p>Recent industry updates</p>
        <div v-if="!loading">
          <div class="shadow py-3 px-3 mb-4 bg-white" v-for="(item, index) in news" :key="'news-' + index">
            <div>
              <router-link :to="{ name: 'NewsItem', params: { id: item.id }}">
                <div><strong>{{item.name}}</strong></div>
                <div><small>{{item.date}} - {{ item.countryName }} - {{ item.publisher }}</small></div>
              </router-link>
            </div>
          </div>
          <div class="shadow py-3 px-3 mb-4 bg-white">
            <router-link :to="{ name: 'ImpactPoliciesNews' }" class="link-card">More news</router-link>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import contactForm from '@/components/ContactForm.vue'

export default {
  name: 'PoliciesHome',
  components: {
    contactForm
  },
  computed: {
    countrySelected: {
      get () {
        return this.$store.state.countryCPRD1
      },
      set (payload) {
        this.$store.commit('setCountryCPRD1', payload)
      }
    },
    countrySelected2: {
      get () {
        return this.$store.state.countryCPRD2
      },
      set (payload) {
        this.$store.commit('setCountryCPRD2', payload)
      }
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  data () {
    return {
      loading: true,
      news: []
    }
  },
  created: async function () {
    document.title = "Climate Policy and Regulatory Dashboard"
    this.news = await this.$Amplify.API.get('cosmos', '/news/anypolicy/8')
    this.loading = false
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
.link-card {
  font-size: 16px;
  font-weight: bold;
}
</style>
