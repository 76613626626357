<template>
  <div>
    <b-container>
      <b-row>
        <b-col>
          <h2 class="mt-5" style="font-size: 20px; font-weight: bold;">Contact us</h2>
          <p>Provide feedback, request features or support</p>
          <b-container class="shadow p-3 bg-white">
            <b-row>
              <b-col>
                <contact-form />
              </b-col>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import contactForm from '@/components/ContactForm.vue'

export default {
  name: 'CPRDHelpAndSupport',
  components: {
    contactForm
  },
  computed: {
  },
  data () {
    return {
    }
  },
  created: async function () {
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
.link-card {
  font-size: 16px;
  font-weight: bold;
}
</style>
